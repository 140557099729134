import Service from "../Service";

const resource = "collection/";

export default {
    searchCustomer(requestID, Dni, rute, positionCodSales) {
        return Service.post(resource + "searchCustomer", "", {
            params: { requestID: requestID, Dni: Dni, rute: rute, positionCodSales: positionCodSales },
        });
    },

    save(obj, requestID) {

        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    saveLot(obj, requestID) {
        return Service.post(resource + "saveLot", obj, {
            params: { requestID: requestID },
        });
    },


    saveGestion(obj, requestID) {
        return Service.post(resource + "saveGestion", obj, {
            params: { requestID: requestID },
        });
    },
};