<template>
<div>
    <v-container class="px-0">
        <v-row>
            <v-col cols="12">
                <!-- <s-toolbar :color="'#244093'" dark label="Consulta de Clientes EVOLTA" class="tran"></s-toolbar> -->
                <v-card>

                    <v-row style="margin-top: 0px;">

                        <v-col cols="12">
                            <v-card class="mx-auto my-1" width="604">
                                <v-row justify="center" style="margin-top: 4px; margin-left: 10px;">
                                    <v-col cols="9" lg="9" md="9">
                                        <s-text v-model="txtDocIdentidad" label="Ingrese Doc. Identidad"></s-text>
                                    </v-col>
                                    <v-col cols="3" lg="3" md="3">
                                        <v-btn elevation="0" class="mt-4" small color="success" @click="btnSearch()">
                                            Buscar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-card-title>
                                    <b>Titular</b>
                                    <v-btn v-if="item.length > 0" color="error" class="ml-3" x-small @click="btnMigrate()">
                                        Migrar All
                                    </v-btn>
                                </v-card-title>

                                <v-card-text>
                                    <div class="text-subtitle-1">
                                        <b>{{ itemOne.CobCustomnerName}}</b>
                                    </div>
                                    <div class="text-subtitle-1">
                                        {{itemOne.CobTypeDocument}} • <b>{{ itemOne.CobCustomerDocument}}</b>
                                    </div>

                                    <div>Direccion: {{itemOne.CobCustomerAddress}}</div>
                                    <div>Email: {{itemOne.CobCustomerEmail}}</div>
                                    <div>Celular: {{itemOne.CobCustomerTelephone}}</div>
                                    <div>Nacionalidad: {{itemOne.CobCustomerNacionality}}</div>
                                    <div>Provincia: {{itemOne.CobCustomerProvince}}</div>
                                    <div>Distrito: {{itemOne.CobCustomerDistrict}}</div>

                                </v-card-text>

                                <v-divider class="mx-4"></v-divider>

                                <v-card-title><b>Lote: </b></v-card-title>
                                <v-card-text v-for="obj in item" :key="obj.CobNumberSale">
                                                                
                                    <div>Proyecto: <b>{{obj.CobDraft}}</b></div>
                                    <div>Etapa: <b>{{obj.CobStage}}</b></div>
                                    <div>Nro Inmueble: <b>{{obj.CobNumberInmueble}}</b></div>
                                    <div>Codigo de Venta: <b>{{obj.CobNumberSale}}</b></div>
                                    <div><b><v-btn v-if="item.length > 1" elevation="0" color="info" style="text-transform: initial;"  x-small @click="btnMigrateLot(obj)">Migrar</v-btn></b></div>

                                </v-card-text>


                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table dense :headers="headers" :items="item" :items-per-page="-1" hide-default-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</div>
</template>

<script>
import _sCollection from "@/services/Collection/CobSearcCustomerService.js"
import _sGenParam from "@/services/General/ParameterService";

export default {
    components: {},
    data() {
        return {
            processing: false,
            messageProcessing: "",
            txtDocIdentidad: "",
            rute: 'C:',
            positionCodSales: 401,
            item: [],
            
            itemOne: {
                CobDraft: "",
                CobStage: "",
                CobNumberInmueble: "",
                CobTypeDocument: "",
                CobCustomerDocument: "",
                CobCustomnerName: "",
                CobCustomerAddress: "",
                CobCustomerEmail: "",
                CobCustomerTelephone: "",
                CobCustomerProvince: "",
                CobCustomerDistrict: "",
                CobCustomerNacionality: "",
                CobNumberSale: "",
            },
            headers: [
                /*  {
                     text: "action",
                     value: "action",
                     width: 50
                 }, */
                {
                    text: "Proyecto",
                    value: "CobDraft",
                    width: 200
                },
                {
                    text: "Etapa",
                    value: "CobStage",
                    width: 100
                },
                {
                    text: "Nro. Inmueble",
                    value: "CobNumberInmueble",
                    width: 100
                },
                {
                    text: "Tipo Doc.",
                    value: "CobTypeDocument",
                    width: 100
                },
                {
                    text: "Nro. Dni",
                    value: "CobCustomerDocument",
                    width: 100
                },
                {
                    text: "Nombre Titular",
                    value: "CobCustomnerName",
                    width: 200
                },
                {
                    text: "Dirección",
                    value: "CobCustomerAddress",
                    width: 200
                },
                {
                    text: "Email",
                    value: "CobCustomerEmail",
                    width: 100
                },
                {
                    text: "Celular",
                    value: "CobCustomerTelephone",
                    width: 100
                },
                {
                    text: "Provincia",
                    value: "CobCustomerProvince",
                    width: 100
                },
                {
                    text: "Distrito",
                    value: "CobCustomerDistrict",
                    width: 100
                },
                {
                    text: "Nacionalidad",
                    value: "CobCustomerNacionality",
                    width: 100
                },
                {
                    text: "N° de Venta",
                    value: "CobNumberSale",
                    width: 100
                },
            ]
        };
    },

    created() {this.loadParameter()},

    watch: {},

    methods: {

        loadParameter() {
            _sGenParam
                .search({
                    PrmName: "RutaArchivoBuscaCliente"
                }, this.$fun.getUserID())
                .then((resp) => {
                    if (resp.status == 200) {
                        this.rute = resp.data.PrmValue;
                    }
                });
        },

        btnSearch() {

            this.messageProcessing = ""
            if (this.txtDocIdentidad.length == 0 || this.txtDocIdentidad == "" || this.txtDocIdentidad == null) {
                this.$fun.alert("Ingrese Documento de Identidad", "warning")
                return;
            }

            if (this.txtDocIdentidad.length < 8) {
                this.$fun.alert("Documento Invalido", "warning")
                return;
            }

            this.processing = true;
            _sCollection.searchCustomer(this.$fun.getUserID(), this.txtDocIdentidad, this.rute, this.positionCodSales).then(r => {
                    if (r.status == 200) {
                        this.processing = false;
                        this.item = r.data.filter(x => x.CobCustomerDocument == this.txtDocIdentidad);

                        if (this.item.length > 0) {
                            this.itemOne = this.item[0]
                        }
                    }

                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        },

        btnMigrate() {

            this.$fun.alert("¿Seguro de Migrar el registro seleccionado al Sistema de Cobranzas?", "question").then(x => {

                if (x.value) {

                    _sCollection.save(this.itemOne, this.$fun.getUserID()).then(resp => {

                        if (resp.status == 200) {
                            if (resp.data.xFlag == 1) {
                                this.$fun.alert("Migrado Correctamente", "success")
                                
                                //this.itemOne = {}
                            } else {
                                this.$fun.alert("Error al realizar la migraciòn", "error")
                            }
                        }
                    })
                }
                /* 
                } */
            })
        },

        btnMigrateLot(item) {

            this.$fun.alert("¿Seguro de Migrar el Cod. Venta: " + item.CobNumberSale +" al Sistema de Cobranzas?", "question").then(x => {

                if (x.value) {

                    _sCollection.saveLot(item, this.$fun.getUserID()).then(resp => {

                        if (resp.status == 200) {
                            if (resp.data.resp == 1) {
                                this.$fun.alert("Venta migrado correctamente", "success")
                            } else {
                                this.$fun.alert("Error al realizar la migraciòn", "error")
                            }
                        }
                    })
                }
                /* 
                } */
            })
        }
    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
